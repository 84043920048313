<template lang="pug">
.currency(:class="[{'--reversed': isReversed}, `--${type}`, {'--hide': isBalanceHide}]")
  .currency__text(v-if="isReversed")
    slot
  .currency__icon
    img.currency__ticket(
      v-if="type === 'tickets'"
      :height="size"
      :width="size"
      :src="require('~/assets/img/icons/trophy.svg')"
      alt="tockets"
    )
    img.currency__star(
      v-else-if="type === 'stars'"
      :height="size"
      :width="size"
      :src="require('~/assets/img/star.svg')"
      alt="stars"
    )
    img.currency__euro(
      v-else-if="type === 'money' && rub"
      :height="size"
      :width="size"
      src="/currencies/rub.svg"
      alt="rub"
    )
    img.currency__euro(
      v-else-if="type === 'money' && real"
      :height="size"
      :width="size"
      src="/currencies/eur.svg"
      alt="eur"
    )
    img.currency__g(
      v-else-if="type === 'money'"
      :height="size"
      :width="size"
      src="/currencies/v.svg"
      alt="g-cash"
    )
    img.currency__coins(v-else :height="size" :width="size" :src="require('~/assets/img/icons/coins-new.svg')" alt="coins")
  .currency__text(v-if="!isReversed")
    slot
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'Currency',

  props: {
    type: {
      type: String,
      default: 'coins'
    },

    size: {
      type: String,
      default: '24'
    },

    isReversed: {
      type: Boolean,
      default: false
    },

    real: {
      type: Boolean,
      default: false
    },

    rub: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState('user', ['user', 'isBalanceHide'])
  },

  beforeMount () {
    this.toggleUserBalanceVisibility(
      window.localStorage.getItem('isBalanceHide')
    )
  },

  methods: {
    ...mapMutations('user', ['toggleUserBalanceVisibility'])
  }
}
</script>

<style lang="scss" scoped>
.currency {
  display: inline-flex;
  align-items: center;

  &.--hide {
    .currency__text {
      background: rgb(255 255 255 / 30%);
      filter: blur(6px);
      border-radius: 4px;
    }
  }

  &.--tickets {
    img {
      margin-right: 2px;
    }
  }

  &.--reversed {
    img {
      margin-left: 4px;
      margin-right: 0;
    }
  }

  &__icon {
    height: auto;
    margin-right: 2px;
  }

  img {
    display: block;
    height: 100%;
  }

  &__coins path {
    fill: $amber50;
  }

  &__star path {
    fill: $amber50;
  }

  @media (max-width: $breakpointMobile) {
    &__icon {
      margin-right: 0;
    }
  }
}
</style>
